import React from "react";
import logo1 from "assests/mongodb-logo.svg";
import logo2 from "assests/yelp_logo.webp";
import logo3 from "assests/shaw-floors-vector.svg";
import logo4 from "assests/nilfisk-logo.webp";
import logo5 from "assests/dd_logo.svg";
import logo6 from "assests/snowflake-logo.svg";
import logo7 from "assests/thomson-reuters-logo-vector.svg";
import logo8 from "assests/box-logo-blue.svg";
import { Link, useNavigate } from "react-router-dom";

/**
 * AboutUs component
 * @returns {jsx}
 */
const AboutUs = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact");
  };

  return (
    <div className="about-page bg-gray-100 text-gray-800 p-8">
      <div className="max-w-5xl mx-auto">
        {/* Заголовок страницы */}
        <h1 className="text-4xl font-bold text-center text-blue-800 mb-8">
          About Us
        </h1>

        {/* Описание компании */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-blue-800 mb-4">
            Our Mission
          </h2>
          <p className="text-lg mb-6">
            We aim to revolutionize the travel industry by providing our clients
            with top-notch travel products at the best prices, extensive
            inventory options, and flexible terms. Through advanced technology
            and a client-centric approach, we empower travel businesses around
            the globe to find the best travel solutions that meet their unique
            needs.
          </p>
        </section>

        {/* Описание компании и её цели */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-blue-800 mb-4">
            Who We Are
          </h2>
          <p className="text-lg mb-6">
            Founded in 2015, we are a global travel product marketplace,
            delivering diverse accommodation options, experiences, and travel
            services to B2B clients, including tour operators, wholesalers, and
            retail agencies. With headquarters in Antalya, our team of over 150
            experts supports clients in 60+ countries with seamless software
            solutions and operational efficiency.
          </p>
        </section>

        {/* Почему стоит выбрать нас */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-blue-800 mb-4">
            Why Choose Us
          </h2>
          <ul className="list-disc pl-6 space-y-3">
            <li>
              <strong>Modern Solutions:</strong> We utilize cutting-edge
              technology to deliver sustainable and efficient travel solutions.
            </li>
            <li>
              <strong>Global Network:</strong> With partners in over 60
              countries, we bring a world of travel options to our clients’
              fingertips.
            </li>
            <li>
              <strong>Trusted Expertise:</strong> Our solutions are developed by
              tourism professionals with decades of experience in the industry.
            </li>
            <li>
              <strong>24/7 Support:</strong> Our team is available around the
              clock to ensure uninterrupted service for our clients.
            </li>
          </ul>
        </section>

        {/* Наша команда */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-blue-800 mb-4">
            Our Team
          </h2>
          <p className="text-lg mb-4">
            Our dedicated team of software developers, product managers, and
            travel experts works tirelessly to enhance our platform, streamline
            operational processes, and deliver value to our clients. We focus on
            innovation and responsiveness to provide exceptional service and
            products to the travel industry.
          </p>
        </section>

        {/* Наша инфраструктура */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-blue-800 mb-4">
            Our Infrastructure
          </h2>
          <p className="text-lg mb-4">
            Leveraging our industry-leading software brands like Sejour and
            TourVisio, we enable travel companies to manage bookings, inventory,
            and client relations with ease. Our integrations allow seamless
            connectivity with suppliers, enhancing operational efficiency and
            expanding our clients’ reach.
          </p>
        </section>

        {/* Призыв к действию */}
        <section className="text-center mb-8">
          <p className="text-lg mb-4">
            Ready to elevate your travel business? Join us and experience the
            difference.
          </p>
          <Link
            to="/contact"
            className="bg-yellow-500 text-black font-semibold px-6 py-3 rounded-lg hover:bg-yellow-600 transition duration-300"
          >
            Get in Touch
          </Link>
        </section>

        {/* Раздел с логотипами партнеров */}
        <div className="my-16 text-center">
          <h3 className="text-3xl font-bold text-yellow-400 mb-8">
            Trusted by Global Brands
          </h3>
          <div className="flex flex-wrap justify-center items-center gap-6">
            <img src={logo1} alt="MongoDB logo" className="h-12" />
            <img src={logo2} alt="Yelp logo" className="h-12" />
            <img src={logo3} alt="Shaw Floors logo" className="h-12" />
            <img src={logo4} alt="Nilfisk logo" className="h-12" />
            <img src={logo5} alt="DD logo" className="h-12" />
            <img src={logo6} alt="Snowflake logo" className="h-12" />
            <img src={logo7} alt="Thomson Reuters logo" className="h-12" />
            <img src={logo8} alt="Box logo" className="h-12" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
