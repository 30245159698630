import { useNavigate } from "react-router-dom";
import banner from "assests/banner.jpg";
import logo1 from "assests/logo1.svg";
import logo2 from "assests/logo2.svg";
import logo3 from "assests/logo3.svg";
import logo4 from "assests/logo4.svg";
import logo5 from "assests/logo5.svg";
import logo6 from "assests/logo6.svg";
import booking from "assests/booking.jpg";
import support from "assests/support.webp";
import global from "assests/global.webp";

const Home = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate("/contact");
  };

  const faqData = [
    {
      question: "What is Paximumm",
      answer:
        "paximummis a global B2B marketplace providing access to travel services.",
    },
    {
      question: "How do I join?",
      answer: "Click 'Agency Application Form' to register your agency.",
    },
    {
      question: "What products do you offer?",
      answer: "We offer inventory from hotels, airlines, and wholesalers.",
    },
    {
      question: "Is support available 24/7?",
      answer: "Yes, our support team is available 24/7 to assist you.",
    },
  ];

  return (
    <div className="font-sans bg-blue-900 text-white">
      {/* Hero Section */}
      <div className="relative">
        <img
          src={banner}
          alt="Global Travel Marketplace"
          className="w-full h-[80vh] object-cover opacity-90"
        />
        <div className="absolute inset-0 bg-blue-800 bg-opacity-80 flex flex-col justify-center items-center text-center">
          <h1 className="text-5xl md:text-7xl font-extrabold text-yellow-400 mb-4">
            Global Travel Marketplace
          </h1>
          <p className="text-xl md:text-2xl max-w-2xl mb-8">
            Connect with top-tier travel services from worldwide suppliers,
            tailored for your B2B needs.
          </p>
          <button
            onClick={handleLearnMoreClick}
            className="bg-yellow-500 hover:bg-yellow-600 transition-colors text-black px-8 py-4 rounded-lg font-semibold text-lg"
          >
            Learn More
          </button>
        </div>
      </div>

      {/* Ecosystem Section */}
      <section className="py-12 bg-blue-800 text-center">
        <h2 className="text-3xl font-bold text-yellow-400 mb-6">
          paximummEcosystem
        </h2>
        <p className="text-lg text-gray-300 max-w-3xl mx-auto mb-8">
          Over 200 million daily searches, 1,000,000+ hotels in 95,000
          destinations, and a global network of airlines and wholesalers.
        </p>
        <button
          onClick={handleLearnMoreClick}
          className="bg-yellow-500 hover:bg-yellow-600 transition-colors text-black px-6 py-3 rounded-lg font-semibold"
        >
          Learn More About Our Ecosystem
        </button>
      </section>

      {/* Key Features Section */}
      <section className="p-16 bg-blue-700 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
        <div className="flex flex-col justify-center text-center md:text-left">
          <h3 className="text-3xl font-semibold text-yellow-400 mb-4">
            Trusted Hotel Network
          </h3>
          <p className="text-lg text-gray-300">
            Access a vast inventory with direct contracts across 6,000+ hotels
            globally.
          </p>
        </div>
        <img src={booking} alt="Booking" className="rounded-lg shadow-lg" />

        <img src={support} alt="Support" className="rounded-lg shadow-lg" />
        <div className="flex flex-col justify-center text-center md:text-left">
          <h3 className="text-3xl font-semibold text-yellow-400 mb-4">
            24/7 Dedicated Support
          </h3>
          <p className="text-lg text-gray-300">
            Our multilingual support team is here around the clock to assist
            you.
          </p>
        </div>
      </section>

      {/* Секция с клиентами */}
      <section className="py-16 bg-blue-700">
        <h2 className="text-3xl font-bold text-center mb-8">Our Clients</h2>
        <div className="flex flex-wrap justify-center items-center gap-8">
          <img
            src={logo1}
            alt="Client 1"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo2}
            alt="Client 2"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo3}
            alt="Client 3"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo4}
            alt="Client 4"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo5}
            alt="Client 5"
            className="w-32 h-32 object-contain"
          />
          <img
            src={logo6}
            alt="Client 6"
            className="w-32 h-32 object-contain"
          />

          {/* Добавить остальные логотипы... */}
        </div>
      </section>

      {/* Global Network Section */}
      <section className="py-20 bg-gradient-to-r from-blue-400 to-blue-900 text-center">
        <img src={global} alt="Global Network" className="h-90 mx-auto mb-8" />
        <h2 className="text-4xl font-bold text-yellow-400 mb-4">
          Worldwide Supplier Network
        </h2>
        <p className="text-lg text-gray-300 max-w-2xl mx-auto">
          Join our network, connecting suppliers and agencies across 60+
          countries and 4 continents.
        </p>
      </section>

      {/* FAQ Section */}
      <section className="py-16 bg-blue-900 text-white">
        <h2 className="text-3xl font-bold text-center mb-8 text-yellow-400">
          Frequently Asked Questions
        </h2>
        <div className="max-w-3xl mx-auto">
          <div className="space-y-4">
            {faqData.map((faq, index) => (
              <details key={index} className="bg-blue-800 p-4 rounded-lg">
                <summary className="font-semibold cursor-pointer">
                  {faq.question}
                </summary>
                <p className="mt-2 text-gray-300">{faq.answer}</p>
              </details>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 bg-blue-800 text-white text-center">
        <h2 className="text-3xl font-bold text-yellow-400 mb-8">
          Client Feedback
        </h2>
        <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-blue-700 p-6 rounded-lg shadow-md">
            <p className="text-xl mb-4 text-gray-300">
              "Reliable and efficient service for all our travel needs!"
            </p>
            <p className="font-semibold text-yellow-400">
              - John D., TravelCorp
            </p>
          </div>
          <div className="bg-blue-700 p-6 rounded-lg shadow-md">
            <p className="text-xl mb-4 text-gray-300">
              "Our agency's preferred platform for global booking."
            </p>
            <p className="font-semibold text-yellow-400">
              - Sarah T., WorldTours
            </p>
          </div>
        </div>
      </section>

      {/* API Integration Section */}
      <section className="py-20 bg-blue-900 text-center text-white">
        <h2 className="text-4xl font-bold text-yellow-400 mb-6">
          Seamless API Integration
        </h2>
        <p className="text-lg max-w-3xl mx-auto mb-6 text-gray-300">
          Integrate with our flexible API solutions for a streamlined booking
          process tailored to your business.
        </p>
        <button
          onClick={handleLearnMoreClick}
          className="bg-yellow-500 hover:bg-yellow-600 transition-colors text-black px-6 py-3 rounded-lg font-semibold"
        >
          Discover Our API
        </button>
      </section>

      {/* Awards Section */}
      <section className="py-20 bg-blue-800 text-center text-white">
        <h2 className="text-4xl font-bold text-yellow-400 mb-6">
          Industry Recognition
        </h2>
        <p className="text-lg max-w-3xl mx-auto mb-6 text-gray-300">
          Honored globally for excellence in travel services and innovation.
        </p>
        <div className="text-yellow-400 font-semibold">
          AWARDED: Global Travel Awards 2023, TravelTech 2024
        </div>
      </section>
    </div>
  );
};

export default Home;
