import React from "react";

const Career = () => {
  return (
    <div className="career-page bg-gray-100 text-gray-800 p-8">
      <div className="max-w-4xl mx-auto">
        {/* Заголовок страницы */}
        <h1 className="text-4xl font-bold text-center text-blue-800 mb-8">
          Join Our Team
        </h1>

        {/* Описание компании */}
        <section className="mb-12">
          <p className="text-lg text-center mb-6">
            At our company, we are committed to excellence in the travel
            industry through technology, innovation, and teamwork. We seek
            motivated individuals who are passionate about contributing to our
            mission of delivering top-quality travel solutions and experiences
            for our B2B clients. Join us and become a part of a dedicated team
            that values creativity, collaboration, and growth.
          </p>
        </section>

        {/* Почему стоит работать с нами */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-blue-800 mb-4">
            Why Work With Us?
          </h2>
          <ul className="list-disc pl-6 space-y-3">
            <li>
              <strong>Innovation and Technology:</strong> Work at the cutting
              edge of travel technology, pioneering seamless experiences through
              advanced, fast, and reliable solutions.
            </li>
            <li>
              <strong>Growth and Development:</strong> We invest in our
              employees’ skills and knowledge, encouraging career progression
              and personal growth.
            </li>
            <li>
              <strong>Collaborative Environment:</strong> We value open
              communication, teamwork, and respect, believing that together, we
              can achieve extraordinary results.
            </li>
          </ul>
        </section>

        {/* Процесс набора */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-blue-800 mb-4">
            Recruitment Process
          </h2>
          <ol className="list-decimal pl-6 space-y-3">
            <li>
              <strong>Application Review:</strong> We carefully evaluate
              candidates' qualifications to find the best fit for each open
              role.
            </li>
            <li>
              <strong>Reference Check:</strong> We verify professional
              backgrounds through references.
            </li>
            <li>
              <strong>Interview:</strong> Candidates discuss their experience
              with our team, and some roles may require a technical interview.
            </li>
            <li>
              <strong>Personality Assessment:</strong> We seek team members who
              align with our culture, so personality assessments may be used.
            </li>
            <li>
              <strong>Trial Period:</strong> Selected candidates undergo a trial
              period to ensure a smooth transition into the team.
            </li>
            <li>
              <strong>Offer:</strong> Successful candidates receive an official
              offer to join our dynamic team.
            </li>
          </ol>
        </section>

        {/* Департаменты */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-blue-800 mb-4">
            Our Departments
          </h2>
          <ul className="list-disc pl-6 space-y-3">
            <li>
              <strong>Project and Software Development:</strong> Teams include
              Sejour, TourVisio, B2B, UI/UX, and Mobile Developers.
            </li>
            <li>
              <strong>Sales and Business Development:</strong> Focus on Paximum
              and International sales teams.
            </li>
            <li>
              <strong>Support and IT:</strong> Customer support and IT support
              teams.
            </li>
            <li>
              <strong>Finance and Administration:</strong> Accounting and
              administrative support teams.
            </li>
            <li>
              <strong>Human Resources:</strong> HR Management and recruitment.
            </li>
            <li>
              <strong>Senior Management:</strong> Leadership roles guiding
              strategy and growth.
            </li>
          </ul>
        </section>

        {/* Возможности роста */}
        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-blue-800 mb-4">
            Opportunities for Growth
          </h2>
          <p className="text-lg mb-4">
            From developer roles in cutting-edge technology to positions in
            business development, we have a role for every professional seeking
            to make a meaningful impact. Here are some areas where you can
            thrive with us:
          </p>
          <ul className="list-disc pl-6 space-y-3">
            <li>
              <strong>Tech and Innovation:</strong> Work on groundbreaking
              projects that redefine travel tech.
            </li>
            <li>
              <strong>Customer-Centric Solutions:</strong> Join teams focused on
              delivering unparalleled service to our clients worldwide.
            </li>
            <li>
              <strong>Leadership and Strategy:</strong> Shape the future of the
              travel industry through strategic decision-making.
            </li>
          </ul>
        </section>

        {/* Призыв к действию */}
        <section className="text-center mb-8">
          <p className="text-lg mb-4">
            Ready to join? Take the first step towards a rewarding career in
            travel technology!
          </p>
          <a
            href="#"
            className="bg-blue-800 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-900 transition"
          >
            Submit Your Application
          </a>
        </section>
      </div>
    </div>
  );
};

export default Career;
