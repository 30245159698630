// GlobalNavbar.jsx
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "components/hamburger-menu/HamburgerMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "contexts/AuthContext";
import NavbarItems from "components/navbar-items/NavbarItems";

const GlobalNavbar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);

  const onHamburgerMenuToggle = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="relative flex justify-between items-center px-8 py-4 md:px-16 bg-blue-900 text-white shadow-lg">
      {/* Логотип */}
      <div className="flex items-center">
        <Link to="/">
          <span className="font-bold text-2xl text-yellow-500">paximumm</span>
        </Link>
      </div>

      {/* Основные ссылки для больших экранов */}
      <ul className="hidden md:flex space-x-8">
        <NavbarItems isAuthenticated={isAuthenticated} />
      </ul>

      {/* Иконка для меню на маленьких экранах */}
      <FontAwesomeIcon
        icon={faBars}
        size="lg"
        color="#FFD700"
        className="block md:hidden cursor-pointer"
        onClick={onHamburgerMenuToggle}
      />

      {/* Меню для мобильных устройств */}
      <HamburgerMenu
        isVisible={isVisible}
        onHamburgerMenuToggle={onHamburgerMenuToggle}
        isAuthenticated={isAuthenticated}
      />
    </div>
  );
};

export default GlobalNavbar;
