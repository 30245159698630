import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <div className="bg-black text-white">
      {/* Header Section */}
      <header className="bg-gradient-to-r from-blue-900 to-purple-700 p-8 text-center">
        <h1 className="text-4xl font-bold text-yellow-500">Our Cutting-Edge Technology</h1>
        <p className="mt-4 text-xl">
          Unleashing the power of travel with advanced, reliable, and scalable technology.
        </p>
      </header>

      {/* Objective Section */}
      <section className="container mx-auto px-8 py-12">
        <h2 className="text-3xl font-semibold text-yellow-500">Our Objective</h2>
        <p className="mt-4">
          Our mission is to provide travel clients with the best prices, extensive inventory, and efficient
          booking conditions through innovative technology. Built with scalability and speed, paximummensures that
          each client has access to real-time, reliable data at any time.
        </p>
      </section>

      {/* Key Features Section */}
      <section className="bg-gray-800 py-12 px-8">
        <div className="container mx-auto">
          <h2 className="text-3xl font-semibold text-yellow-500">Key Technology Features</h2>
          <p className="mt-4">
            Paximum’s technological infrastructure is designed to accommodate the demands of the travel industry.
            From cloud-based scalability to real-time data delivery, our platform meets the needs of B2B partners worldwide.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
            <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-yellow-500">Scalable Infrastructure</h3>
              <p className="mt-2">
                Paximum’s cloud-based, scalable infrastructure on Amazon Cloud ensures uninterrupted service,
                even with high demand. Capable of processing thousands of hotel searches within seconds, our
                platform handles heavy traffic effortlessly.
              </p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-yellow-500">Latest Technology</h3>
              <p className="mt-2">
                Built with an agile approach and the latest tech advancements, our platform seamlessly integrates
                with various client systems, providing efficient, hassle-free experiences.
              </p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-yellow-500">API-Driven Flexibility</h3>
              <p className="mt-2">
                Our state-of-the-art API supports a range of applications, from mobile and Whitelabel to B2B
                and B2C platforms, allowing clients to expand their services with minimal friction.
              </p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-yellow-500">Real-Time Data</h3>
              <p className="mt-2">
                paximummdelivers real-time inventory updates through API integration, enabling buyers to access
                the latest hotel options instantly. This ensures an updated and accurate inventory at all times.
              </p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-yellow-500">Advanced Mapping Algorithms</h3>
              <p className="mt-2">
                Using Giata IDs and internal algorithms, paximummmaps hotels accurately, ensuring seamless data
                alignment and faster search responses for clients.
              </p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-yellow-500">Optimized Cache Delivery</h3>
              <p className="mt-2">
                Our Cache API is tailored for high-demand tour operator systems, providing real-time updates
                and ensuring rapid data access for clients and suppliers alike.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Integration Section */}
      <section className="container mx-auto px-8 py-12">
        <h2 className="text-3xl font-semibold text-yellow-500">Seamless Integration & Ecosystem</h2>
        <p className="mt-4">
          paximummseamlessly integrates with systems like Sejour and Tourvisio, enabling effortless data sharing
          and management between buyers and sellers. Our ecosystem connects over 150 suppliers and thousands of
          buyers, ensuring consistent data flow.
        </p>

      </section>

      {/* Technology Highlights Section */}
      <section className="bg-gray-800 py-12 px-8">
        <div className="container mx-auto">
          <h2 className="text-3xl font-semibold text-yellow-500">Technology Highlights</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
            <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-yellow-500">Real-Time JSON API</h3>
              <p className="mt-2">
                Our JSON API ensures that buyers can access live hotel inventory globally, with fast and accurate
                response times for searches and bookings.
              </p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold text-yellow-500">Integrated Sejour Gateway</h3>
              <p className="mt-2">
                The Sejour Gateway centralizes local leisure products, offering regularly updated allotments and
                rates through advanced cache mechanisms for quick and reliable access.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact and Footer */}
      <section className="container mx-auto px-8 py-12">
        <h2 className="text-3xl font-semibold text-yellow-500">Contact Us for More Information</h2>
        <p className="mt-4">Explore how our technology can drive your travel business forward.</p>
        <div className="mt-6">
          <Link
            to="/contact"
            className="bg-yellow-500 text-black font-semibold px-6 py-3 rounded-lg hover:bg-yellow-600 transition duration-300"
          >
            Get in Touch
          </Link>
        </div>
      </section>

      <footer className="bg-black text-gray-500 py-8 text-center">
        <p>&copy; {new Date().getFullYear()} Paximumm All Rights Reserved.</p>
        <div className="mt-4 flex justify-center space-x-4">
          <Link to="/privacy-policy" className="hover:text-yellow-500">Privacy Policy</Link>
          <Link to="/cookie-policy" className="hover:text-yellow-500">Cookie Policy</Link>
          <Link to="/career" className="hover:text-yellow-500">Careers</Link>
        </div>
      </footer>
    </div>
  );
};

export default Pricing;
