import React from "react";

const Contact = () => {
  return (
    <div className="bg-black text-white min-h-screen py-16 px-8 md:px-16">
      {/* Заголовок и описание страницы */}
      <header className="text-center mb-12">
        <h1 className="text-4xl font-bold text-yellow-500 mb-4">Contact Us</h1>
        <p className="text-lg text-gray-400">
          Get in touch with us for inquiries, collaborations, or B2B solutions tailored to your travel business needs.
        </p>
      </header>

      {/* Основной контент страницы */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        {/* Информация о контактах */}
        <section>
          <h2 className="text-2xl font-semibold text-yellow-500 mb-4">
            Contact Information
          </h2>
          <p className="text-gray-300 mb-6">
            Reach out to our team via email, phone, or visit our office for a more personal experience.
          </p>
          <ul className="space-y-4">
            <li>
              <span className="font-bold text-white">Email:</span>{" "}
              <a
                href="mailto:support@paximumm.com"
                className="text-yellow-500 hover:underline"
              >
                support@paximumm.com
              </a>
            </li>
            <li>
              <span className="font-bold text-white">Phone:</span>{" "}
              <a href="tel:+1234567890" className="text-yellow-500 hover:underline">
                +1 333 567 890
              </a>
            </li>
            <li>
              <span className="font-bold text-white">Address:</span>{" "}
              2544 Main str., New York, NY, 10001
            </li>
          </ul>
        </section>

        {/* Форма для B2B компаний */}
        <section>
          <h2 className="text-2xl font-semibold text-yellow-500 mb-4">
            Business Inquiry Form
          </h2>
          <p className="text-gray-300 mb-6">
            Fill out the form below, and our team will get back to you with tailored solutions for your business.
          </p>
          <form className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
            <div>
              <label className="block text-white mb-2" htmlFor="company-name">
                Company Name
              </label>
              <input
                type="text"
                id="company-name"
                placeholder="Enter your company name"
                className="w-full p-3 rounded bg-black text-white placeholder-gray-500 focus:outline-none focus:ring focus:ring-yellow-500"
              />
            </div>
            <div>
              <label className="block text-white mb-2" htmlFor="contact-person">
                Contact Person
              </label>
              <input
                type="text"
                id="contact-person"
                placeholder="Enter your name"
                className="w-full p-3 rounded bg-black text-white placeholder-gray-500 focus:outline-none focus:ring focus:ring-yellow-500"
              />
            </div>
            <div>
              <label className="block text-white mb-2" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email address"
                className="w-full p-3 rounded bg-black text-white placeholder-gray-500 focus:outline-none focus:ring focus:ring-yellow-500"
              />
            </div>
            <div>
              <label className="block text-white mb-2" htmlFor="phone">
                Phone
              </label>
              <input
                type="tel"
                id="phone"
                placeholder="Enter your phone number"
                className="w-full p-3 rounded bg-black text-white placeholder-gray-500 focus:outline-none focus:ring focus:ring-yellow-500"
              />
            </div>
            <div>
              <label className="block text-white mb-2" htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                rows="4"
                placeholder="Tell us about your business needs"
                className="w-full p-3 rounded bg-black text-white placeholder-gray-500 focus:outline-none focus:ring focus:ring-yellow-500"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full py-3 mt-4 bg-purple-700 hover:bg-yellow-500 text-white font-semibold rounded transition duration-300"
            >
              Submit Inquiry
            </button>
          </form>
        </section>
      </div>

      {/* Дополнительная информация о поддержке */}
      <div className="text-center mt-16">
        <h3 className="text-xl font-semibold text-yellow-500">Customer Support</h3>
        <p className="text-gray-300 mt-4">
          Our customer support team is available 24/7 to assist you with any inquiries. We're here to help your business thrive.
        </p>
      </div>
    </div>
  );
};

export default Contact;
